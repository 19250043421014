import Joi from "joi";

export interface CO2Component {
  loadAttribute?: string;
  _id: string;
}

export interface FilterAttribute {
  name: string;
  title: string;
  options: string[];
  selectedOptions: string[];
  selectorOpened: boolean;
  attributeQuery: string;
}

export interface ReloadPageInfo {
  loadOptions: FilterAttribute[];
  woVersion?: string;
  storeroomVersion?: string;
}

export interface SelectedAttributesOptionsMap {
  [propName: string]: string[];
}

export interface ViewCO2Component {
  co2Component: CO2Component;
  selected: boolean;
  viewComponentLoadStatus?: ComponentReloadStatus;
  countTotal?: number;
  countProcessed?: number;
  currentStep?: string;
}
export const statusMap = {
  DONE: "Done",
  IN_PROGRESS: "In progress",
  ERROR: "Error",
  PENDING: "Pending",
  STOPPED: "Stopped",
  NONE: "None"
};
export interface DBReloadState {
  viewCO2Components?: ViewCO2Component[];
  filterAttributes: FilterAttribute[];
  woVersion?: string;
  storeroomVersion?: string;
  errorMessage: string;
  loadId?: string;
  finalLoadStatus?: ReloadStatuses;
  errorPopupShown: boolean;
  reloadStatusInfoShown: boolean;
  requestStatusIntervalId?: NodeJS.Timeout;
  sortField: SortFields;
  sortOrder: SortOrder;
  lastReloadDate?: string;
}

export enum ReloadStatuses {
  IN_PROGRESS = "IN_PROGRESS",
  ERROR = "ERROR",
  PENDING = "PENDING",
  DONE = "DONE",
  STOPPED = "STOPPED",
  NONE = "NONE"
}
export enum SortFields {
  DB_NAME = "co2Component._id",
  LOAD_ATTRIBUTE = "co2Component.loadAttribute"
}
export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC"
}
export interface ErrorLog {
  errorMessage: string;
}
export interface LoadStepData {
  countProcessed: number;
  countTotal: number;
}
export interface LoadSteps {
  [propName: string]: LoadStepData;
}
export interface ComponentReloadStatus {
  loadStatus: ReloadStatuses;
  currentStep?: string;
  loadSteps: LoadSteps;
}
export interface ReloadStatus {
  loadStatus: ReloadStatuses;
  errorLog?: ErrorLog;
  components: {[propName: string]: ComponentReloadStatus};
  timestemp?: string;
  _id: string;
}
export interface StartPayload {
  components: string[];
  filterAttributes: SelectedAttributesOptionsMap;
}

export interface ReloadData {
  loadId: string;
}

export const RELOAD_STATUS_SUCCESS = "RELOAD_STATUS_SUCCESS";
export const RELOAD_STATUS_FAILURE = "RELOAD_STATUS_FAILURE";
export const SET_RELOAD_STATUS_INTERVALID = "SET_RELOAD_STATUS_INTERVALID";

interface ReloadStatusReceiveAction {
  type: typeof RELOAD_STATUS_SUCCESS;
  data: ReloadStatus;
}
interface ReloadStatusFailAction {
  type: typeof RELOAD_STATUS_FAILURE;
  errorMessage: string;
}
interface SetReloadStatusIntervalIdAction {
  type: typeof SET_RELOAD_STATUS_INTERVALID;
  id?: NodeJS.Timeout;
}

export const ReloadDetailsResponseSchema = Joi.object().keys({
  filterAttributes: Joi.object()
    .keys({
      organization: Joi.array().items(Joi.string()),
      site: Joi.array().items(Joi.string())
    })
    .required(),
  loadStatus: Joi.string().required(),
  components: Joi.object()
    .pattern(
      /[a-zA-Z0-9_]*$/,
      Joi.object().keys({
        loadStatus: Joi.string(),
        loadSteps: Joi.object().pattern(
          /[a-zA-Z0-9_]*$/,
          Joi.object().keys({
            countProcessed: Joi.number(),
            countTotal: Joi.number()
          })
        ),
        currentStep: [Joi.string().optional(), Joi.allow(null)]
      })
    )
    .required(),
  isRefreshing: Joi.boolean().required(),
  timestemp: Joi.string(),
  refreshing: Joi.boolean().required(),
  _id: Joi.string(),
  _rev: Joi.string(),
  errorLog: Joi.object().keys({
    errorMessage: Joi.string().required()
  })
});

export const GENERIC_REQUEST_FAILURE = "GENERIC_REQUEST_FAILURE";
interface GenericRequestFailAction {
  type: typeof GENERIC_REQUEST_FAILURE;
  errorMessage: string;
}
export const GENERIC_REQUEST_SUCCESS = "GENERIC_REQUEST_SUCCESS";
interface GenericRequestSuccessAction {
  type: typeof GENERIC_REQUEST_SUCCESS;
}

export const GENERIC_REQUEST_START = "GENERIC_REQUEST_START";
interface GenericRequestStartAction {
  type: typeof GENERIC_REQUEST_START;
}

export const RELOAD_REQUEST = "RELOAD_REQUEST";
export const RELOAD_SUCCESS = "RELOAD_SUCCESS";

interface ReloadRequestAction {
  type: typeof RELOAD_REQUEST;
}
interface ReloadReceiveAction {
  type: typeof RELOAD_SUCCESS;
  data: ReloadData;
}

export const SHOW_ERROR_POPUP = "SHOW_ERROR_POPUP";
interface ShowErrorPopupAction {
  type: typeof SHOW_ERROR_POPUP;
  show: boolean;
}

export const HIDE_RELOAD_STATUS_INFO = "HIDE_RELOAD_STATUS_INFO";
interface HideReloadStatusInfo {
  type: typeof HIDE_RELOAD_STATUS_INFO;
}

export const SET_SORT = "SET_SORT";

interface SetSortAction {
  type: typeof SET_SORT;
  sortField: SortFields;
  sortOrder: SortOrder;
}

export const SET_ATTRIBUTE_QUERY = "SET_ATTRIBUTE_QUERY";

interface setAttributeQueryAction {
  type: typeof SET_ATTRIBUTE_QUERY;
  name: string;
  attributeQuery: string;
}

export const SELECT_FILTER_ATTRIBUTE = "SELECT_FILTER_ATTRIBUTE";
interface SelectFilterAttributeAction {
  type: typeof SELECT_FILTER_ATTRIBUTE;
  name: string;
  option: string;
}

export const DESELECT_FILTER_ATTRIBUTE = "DESELECT_FILTER_ATTRIBUTE";
interface DeselectFilterAttributeAction {
  type: typeof DESELECT_FILTER_ATTRIBUTE;
  name: string;
  option: string;
}

export const OPEN_FILTER_ATTRIBUTE_SELECTOR = "OPEN_FILTER_ATTRIBUTE_SELECTOR";

interface OpenFilterAttributeSelectorAction {
  type: typeof OPEN_FILTER_ATTRIBUTE_SELECTOR;
  name: string;
  open: boolean;
}

export const CO2_COMPONENT_SELECT_CHANGE = "CO2_COMPONENT_SELECT_CHANGE";
export const CO2_ALL_COMPONENT_SELECT_CHANGE = "CO2_ALL_COMPONENT_SELECT_CHANGE";

interface CO2ComponentSelectChangeAction {
  type: typeof CO2_COMPONENT_SELECT_CHANGE;
  viewCO2Component: ViewCO2Component;
}
interface CO2AllComponentSelectChangeAction {
  type: typeof CO2_ALL_COMPONENT_SELECT_CHANGE;
  checked: boolean;
}

export const CO2_COMPONENTS_SUCCESS = "CO2_COMPONENTS_SUCCESS";

interface ReceiveCO2ComponentsAction {
  type: typeof CO2_COMPONENTS_SUCCESS;
  data: CO2Component[];
}

export const ComponentsResponseSchema = Joi.array().items(
  Joi.object()
    .keys({
      _id: Joi.string().required(),
      loadAttribute: Joi.string().allow(null)
    })
    .unknown()
);

export const RELOAD_PAGE_INFO_SUCCESS = "RELOAD_PAGE_INFO_SUCCESS";
interface ReceiveFilterAttributesAction {
  type: typeof RELOAD_PAGE_INFO_SUCCESS;
  data: ReloadPageInfo;
}

export const ReloadPageInfoSchema = Joi.object().keys({
  loadOptions: Joi.array().items(
    Joi.object().keys({
      name: Joi.string().required(),
      options: Joi.array()
        .items(Joi.string())
        .required()
    })
  ),
  woVersion: Joi.string(),
  storeroomVersion: [Joi.string().optional(), Joi.allow(null)]
});

export type CO2ActionTypes =
  | ReceiveCO2ComponentsAction
  | CO2ComponentSelectChangeAction
  | CO2AllComponentSelectChangeAction
  | ReloadRequestAction
  | ReloadReceiveAction
  | ShowErrorPopupAction
  | HideReloadStatusInfo
  | GenericRequestFailAction
  | GenericRequestStartAction
  | GenericRequestSuccessAction
  | ReloadStatusReceiveAction
  | ReloadStatusFailAction
  | setAttributeQueryAction
  | SetReloadStatusIntervalIdAction
  | SetSortAction
  | ReceiveFilterAttributesAction
  | SelectFilterAttributeAction
  | DeselectFilterAttributeAction
  | OpenFilterAttributeSelectorAction;
