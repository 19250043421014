import React from "react";
import {Modal} from "react-bootstrap";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ScreenExtensionsProps} from "../../types";
import "./configurableButtonsTreeView.scss";

library.add(faMinus);
library.add(faPlus);

export default function ConfigurableFieldsTreeView(props: ScreenExtensionsProps) {
  let confirmDeletePopup = (
    <Modal
      show={true}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => props.showConfirmCancel(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Confirm that you want to delete button?</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => props.showConfirmCancel(false)}>
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            props.showConfirmCancel(false);
            props.deleteConfigButton(props.configButtonToDelete);
          }}
        >
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      {props.showConfirmationDialog && confirmDeletePopup}
      <div className="tree-view" />
    </>
  );
}
