import _ from "lodash";
import React from "react";
import Dropdown from "../../configuration/widgets/Dropdown";
import {ScreenExtensionsProps} from "../types";
import {configurableButtonsConfig} from "../../../../store/screenExtensions/types";
import {ValueType} from "../../../common/input/types";
import ConfigurableButtonsTreeView from "./configurableButtonsTreeView";

export default function ConfigurableButtons(props: ScreenExtensionsProps) {
  const options: configurableButtonsConfig[] = props.configurableButtonsConfig;
  const screens: string[] = _.map(options, "id");
  const onChange = (name: string, value: ValueType) => {
    props.selectButtonScreenChange(name, value);
  };
  return (
    <div className="pt-3">
      <Dropdown
        isValid={true}
        disabled={false}
        title="Screen"
        key="Screen"
        onChange={val => {
          const config =
            _.find(options, option => {
              return option.id === val;
            }) || options[0];
          props.setActiveScreenBtnsConfig(config);
          onChange("id", config.id);
          onChange("group", config.groups[0]);
        }}
        name="Create Work Order"
        options={screens}
        value={_.get(props.activeConfigButton, "id", null)}
        placeholder="Select screen"
      />
      <Dropdown
        isValid={true}
        disabled={!props.activeConfigButton.id}
        title="Section"
        key="Section"
        onChange={val => {
          onChange("group", val);
        }}
        name="Create Work Order"
        options={props.buttonsConfigOnSelectedScreen.groups}
        value={props.activeConfigButton.group}
      />
      <Dropdown
        isValid={true}
        disabled={!props.activeConfigButton.id}
        title="Action"
        key="Action"
        onChange={val => {
          onChange("action", val);
        }}
        name="Create Work Order"
        options={props.buttonsConfigOnSelectedScreen.actions}
        value={props.activeConfigButton.action}
      />
      <div className="col-12 p-0 form-group">
        <label className="pl-0 col-md-2">Label</label>
        <input
          disabled={!props.activeConfigButton.id}
          onChange={(val: React.ChangeEvent<HTMLInputElement>) => onChange("label", val.currentTarget.value)}
          className="form-control col-12"
          type="text"
          value={props.activeConfigButton.label}
        />
      </div>
      <button
        disabled={!props.activeConfigButton.id}
        className="d-flex justify-content-center col-sm-2 btn btn-warning ml-auto"
        onClick={() => {
          props.addMenuButtonOnScreen(props.activeConfigButton);
        }}
      >
        Add Button
      </button>

      {props.configCreateErrorMessage && <label className="alert-danger">{props.configCreateErrorMessage}</label>}

      <ConfigurableButtonsTreeView {...props} />
    </div>
  );
}
