import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {ScreenExtensionsProps} from "../../types";
import {ValueType} from "../../../../common/input/types";
import _ from "lodash";
import Switcher from "../../../configuration/widgets/Switcher";

export default function AddConfigurableField(props: ScreenExtensionsProps) {
  const appDesignerEnabled = process.env.REACT_APP_ENABLE_APP_DESIGNER === "true";

  const searchableScreens = Object.keys(props.configurableFieldsConfig).filter(
    screen => props.configurableFieldsConfig[screen].type === "LIST"
  );

  const [formValidationError, setValidationError] = useState("");

  const addConfigurableField = (event: React.FormEvent<HTMLFormElement>) => {
    const groupConfig = _.find(props.screenConfigWorkingCopy.groups, {id: props.selectScreen["section"]});
    if (
      !props.editConfig &&
      groupConfig &&
      _.some(groupConfig.fields, {fieldName: props.activeConfigField["fieldName"]})
    ) {
      setValidationError("Sorry, but the entered field name is already added in a section");
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    let configField = {
      fieldName: props.activeConfigField["fieldName"],
      order: props.activeConfigField["order"],
      searchable: searchableScreens.includes(props.location.state.screen)
        ? props.activeConfigField["searchable"]
        : undefined
    };

    if (Number(props.activeConfigField.order) < 0) {
      setValidationError('"Order" value cannot be negative');
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (Number(props.activeConfigField.order) > 1000000) {
      setValidationError('"Order" value canot be greater that 100000');
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    let screen = props.selectScreen["screen"];
    let group = props.selectScreen["section"];

    if (props.editConfig) {
      screen = props.activeConfigField["screen"];
      group = props.activeConfigField["section"];
      if (appDesignerEnabled) {
        props.setConfigurableFieldToEdit(configField, screen as string, group as string);
        closeModal();
      } else {
        props.editConfigurableField(configField, screen as string, group as string);
      }
    } else {
      if (appDesignerEnabled) {
        props.setConfigurableFieldToAdd(configField, screen as string, group as string);
        closeModal();
      } else {
        props.addConfigurableField(configField, screen as string, group as string);
      }
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const closeModal = () => {
    props.showAddEditConfigField(false, false);
    setValidationError("");
    props.changeActiveConfigFields({});
  };

  const onChange = (name: string, value: ValueType) => {
    if (name === "fieldName") {
      setValidationError("");
    }
    return props.changeConfigValue(name, value);
  };
  return (
    <Modal centered onHide={closeModal} show={props.showAddEditConfigFieldDialog}>
      <Modal.Header closeButton>New Field</Modal.Header>
      <Modal.Body>
        <form onSubmit={addConfigurableField}>
          {formValidationError && <label className="alert-danger">{formValidationError}</label>}
          <div className="form-group d-flex">
            <label className="pl-0 col-md-2">Field name</label>
            <input
              required
              disabled={props.editConfig}
              onChange={(val: React.ChangeEvent<HTMLInputElement>) => onChange("fieldName", val.currentTarget.value)}
              className="form-control col-md-6"
              type="text"
              value={props.activeConfigField["fieldName"]}
            />
          </div>
          <div className="form-group d-flex">
            <label className="pl-0 col-md-2">Order</label>
            <input
              onChange={(val: React.ChangeEvent<HTMLInputElement>) => onChange("order", val.currentTarget.value)}
              className="form-control col-md-6"
              type="number"
              value={props.activeConfigField["order"]}
            />
          </div>
          {props.location.state && searchableScreens.includes(props.location.state.screen) && (
            <Switcher
              onChange={val => {
                onChange("searchable", val);
              }}
              name="Searchable"
              title="Searchable"
              disabled={false}
              isValid={true}
              type="SLIDER"
              value={!!_.get(props.activeConfigField, "searchable", null)}
            />
          )}
          <div className="float-right">
            <button type="button" onClick={closeModal} className="btn btn-light">
              Cancel
            </button>
            <button type="submit" disabled={!props.activeConfigField["fieldName"]} className="btn btn-warning">
              {appDesignerEnabled ? "Ok" : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
