import _ from "lodash";
import React from "react";
import Dropdown from "../../configuration/widgets/Dropdown";
import {ScreenExtensionsProps} from "../types";
import {ValueType} from "../../../common/input/types";
import ConfigurableFieldsTreeView from "./configurableFieldsTreeView";
import {configurableFieldsConfig} from "../../../../store/screenExtensions/types";
import ScreensTable from "../ScreensTable";
import "../styles.scss";

export default function ConfigurableFields(props: ScreenExtensionsProps) {
  const options: configurableFieldsConfig = props.configurableFieldsConfig;
  const screens: string[] = Object.keys(options);
  const onChange = (name: string, value: ValueType) => {
    props.selectScreenChange(name, value);
  };
  const enableAppDesigner: boolean = process.env.REACT_APP_ENABLE_APP_DESIGNER === "true";
  return (
    <>
      {!enableAppDesigner && (
        <div className="pt-3">
          <Dropdown
            isValid={true}
            disabled={false}
            title="Screen"
            key="Screen"
            onChange={val => {
              onChange("screen", val);
              onChange("section", options[val as string]?.groups[0]);
            }}
            name="Create Work Order"
            options={screens}
            value={_.get(props.selectScreen, "screen", null)}
            placeholder="Select screen"
          />
          <Dropdown
            isValid={true}
            disabled={!options[props.selectScreen["screen"] as string]}
            title="Section"
            key="Section"
            onChange={val => onChange("section", val)}
            name="Create Work Order"
            options={options[props.selectScreen["screen"] as string]?.groups}
            value={_.get(props.selectScreen, "section", null)}
          />
          <button
            disabled={_.isUndefined(props.selectScreen["section"])}
            className="d-flex justify-content-center col-sm-2 btn btn-warning ml-auto"
            onClick={() => props.showAddEditConfigField(true, false)}
          >
            Add Field
          </button>

          <ConfigurableFieldsTreeView {...props} />
        </div>
      )}
      {enableAppDesigner && (
        <>
          <br />
          <ScreensTable {...props} />
        </>
      )}
    </>
  );
}
