import React from "react";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import routes from "../../routes";
library.add(faSync);

const Breadcrumbs = ({children, breadcrumbs}) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb rounded-0 m-0">
      {children}
      {breadcrumbs.map((breadcrumb, index) => {
        // skip Home
        if (index === 0) return null;

        if (index === breadcrumbs.length - 1) {
          return (
            <li className="breadcrumb-item text-capitalize active" aria-current="page" key={breadcrumb.key}>
              {breadcrumb.props.location.state?.breadcrumb || breadcrumb}
            </li>
          );
        } else {
          return (
            <li className="breadcrumb-item text-capitalize" key={breadcrumb.key}>
              <NavLink to={breadcrumb.props.match.url}>{breadcrumb}</NavLink>
            </li>
          );
        }
      })}
    </ol>
  </nav>
);
const breadcrumbConfigShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  props: PropTypes.object.isRequired
});
Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(breadcrumbConfigShape)
};

export default withBreadcrumbs(routes)(Breadcrumbs);
