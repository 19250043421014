import React from "react";
import {Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LinkContainer} from "react-router-bootstrap";
import {ScreenExtensionsProps} from "./types";
import "./styles.scss";

export default function ScreensTable(props: ScreenExtensionsProps) {
  return (
    <Table striped hover>
      <tbody>
        {Object.keys(props.configurableFieldsConfig).map((screen, index) => {
          const linkConfiguration = {
            pathname: "/appDesigner/" + screen,
            state: {
              screen: screen,
              breadcrumb: props.configurableFieldsConfig[screen].label
            }
          };
          return (
            <tr key={index}>
              <td className="align-middle text-primary">
                <LinkContainer style={{cursor: "pointer", display: "inline-block"}} to={linkConfiguration}>
                  <div>{props.configurableFieldsConfig[screen].label}</div>
                </LinkContainer>
              </td>
              <td className="justify-content-end">
                <LinkContainer to={linkConfiguration}>
                  <button type="button" className="btn btn-warning edit-screen-btn" onClick={() => {}}>
                    <FontAwesomeIcon icon="cog" />
                    <span className="edit-btn-caption">Edit</span>
                  </button>
                </LinkContainer>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
