import _ from "lodash";
import {ThunkDispatch} from "redux-thunk";
import {
  fetchConfiguration,
  fetchConfigurationMeta,
  changeValue,
  saveChanges,
  syncCustomUserFieldsToCMMS,
  showConfirmCancel
} from "../../store/configuration/actions";
import {ConfigurationMetadataPart, ConfigurationPart, ConfigurationState} from "../../store/configuration/types";
import {ValueType} from "../../components/common/input/types";
import {getChangedSettings} from "../../store/configuration/utils";

export default function getSharedConfiguration(dispatch: ThunkDispatch<{}, {}, any>) {
  return {
    loadData: async () => {
      dispatch(fetchConfiguration());
      dispatch(fetchConfigurationMeta());
    },
    showConfirmCancel: (show: boolean) => {
      dispatch(showConfirmCancel(show));
    },
    changeValue: (mdPart: ConfigurationMetadataPart, name: string, value: ValueType) => {
      dispatch(changeValue(mdPart, name, value));
    },
    saveChanges: async (configPart: ConfigurationPart, configState: ConfigurationState) => {
      const changedSettings = getChangedSettings(configPart, configState);
      const promises = [];
      if (_.isArray(changedSettings) && changedSettings.length > 0) {
        promises.push(dispatch(saveChanges([{...configPart, settings: changedSettings}])));
      }
      if (_.filter(changedSettings, {name: "formsConfigurationSource"}).length > 0) {
        promises.push(dispatch(syncCustomUserFieldsToCMMS()));
      }
      Promise.all(promises)
        .then(() => dispatch(fetchConfiguration()))
        .catch(error => {
          console.error("An error occurred:", error);
        });
    }
  };
}
